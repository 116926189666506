(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name altmodAccountController
* @description
* This is main controller
**/ 

    angular
    .module('altmod.account')
    .controller('altmodAccountController', altmodAccountController);

    altmodAccountController.$inject = ["altmodAccountService"];

    function altmodAccountController(altmodAccountService)
    {
    	console.log("account is enabled ");
    	var vm = this;
        vm.account = altmodAccountService;



        vm.account.profile().then(function(data){
            console.log(data);
            vm.profile = data;

        });


        vm.valid = function(form,field)
        {
            return (form[field].$invalid && form.$submitted );
        };
        //Sistema de login with apicontroller
        vm.saveProfile = function(form)
        {
            if(!form.$valid)
                return false;

            console.log("Save profile");

            return vm.account.saveProfile(vm.profile).then(function(data)
            {
                console.log(data);
                if(data.code == 200){
                    
                    $window.location.reload();
                }else{
                    vm.loginError = true;
                    $timeout(function()
                    {
                        vm.loginError = false;
                    },5000);
                }

            });
        };

    }


})();