(function() {
    'use strict';
    /**
     * @memberof app
     *@ngdoc service
     *@name altmodAccountService
     *@description
     *Servicio de autores
     */

    angular
        .module('altmod.account')
        .factory('altmodAccountService', altmodAccountService);

    altmodAccountService.$inject = ["$http","$q","$window","$location"];

    function altmodAccountService($http,$q,$window,$location) {  
                console.log("altmod.login Servicio ENABLE");

        var user = {};

        /**
        * @ngdoc method
        * @name login
        * @memberof userService
        * @desciption
        * loguea a un usuario
        */
        user.profile = function (username,password){
            var url= '/api/v1/profile';
             return $http({
                method  : 'GET',
                url     : url,
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };

                /**
        * @ngdoc method
        * @name login
        * @memberof userService
        * @desciption
        * loguea a un usuario
        */
        user.saveProfile = function (data){
            var url= '/api/v1/saveprofile';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "User":data                    
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };




        


        return user;
    	
    }

})();