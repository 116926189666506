(function() {
    'use strict';
    /**
     * @memberof app
     *@ngdoc service
     *@name altmodRegisterService
     *@description
     *Servicio de autores
     */

    angular
        .module('altmod.register')
        .factory('altmodRegisterService', altmodRegisterService);

    altmodRegisterService.$inject = ["$http","$q","$window","$location"];

    function altmodRegisterService($http,$q,$window,$location) {  
                console.log("altmod.registro Servicio ENABLE");

        var user = {};

        

                /**
        * @ngdoc method
        * @name login
        * @memberof userService
        * @desciption
        * loguea a un usuario
        */
        user.registro = function (data){
            var url= '/api/v1/register';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "RegisterForm":data,
                    "lang_id":lang_id                  
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };




        


        return user;
    	
    }

})();