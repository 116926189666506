(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name AltmodLoginController
* @description
* This is main controller
**/ 

    angular
    .module('altmod.login')
    .controller('AltmodLoginController', AltmodLoginController);

    AltmodLoginController.$inject = ['altmodLoginService','$routeParams','$location','$timeout','$window'];

    function AltmodLoginController(altmodLoginService, $routeParams, $location, $timeout,$window)
    {
    	console.log("altmod.login Enable");
    	var vm = this;
    	vm.user = altmodLoginService;
    	vm.username = "";
    	vm.password = "";
        vm.ErrorRecuperar = false;
        vm.loginError = false;
        vm.recuperarView = false;
        vm.OkRecuperar = false;

        if($routeParams.token)
            vm.token = $routeParams.token;

        vm.valid = function(form,field)
        {
            return (form[field].$invalid && form.$submitted );
        };
        //Sistema de login with apicontroller
		vm.login = function(form)
		{
			if(!form.$valid)
				return false;

			return vm.user.login(vm.username,vm.password).then(function(data)
			{
                console.log(data);
                if(data.code == 200){
                    
					$window.location.reload();
                }else{
                    vm.loginError = true;
                    $timeout(function()
                    {
                        vm.loginError = false;
                    },5000);
                }

			});
		};
    //Sistema de login with apicontroller
		vm.logout = function()
		{
			
			console.log("LOGOUT");
			return vm.user.logout().then(function(data)
			{
                console.log(data);
                if(data.code == 200){
                    
					$window.location.reload();
                }else{
                    vm.logoutError = true;
                    $timeout(function()
                    {
                        vm.logoutError = false;
                    },5000);
                }

			});
		};

        vm.setPassword = function(form)
        {
            if(!form.$valid || !vm.token)
                return false;
            return vm.user.setPassword(vm.token,vm.password).then(function(data)
            {
                if(data.code == 404)
                {
                    vm.ErrorRecuperar = true;
                    $timeout(function()
                    {
                        vm.ErrorRecuperar = false;
                    },5000);
                }

                if(data.code == 200){
                    vm.OkRecuperar = true;
                    $timeout(function()
                    {
                        vm.OkRecuperar = false;
                    },5000);

                }

            });
        };

        vm.recuperar = function(form)
        {
            if(!form.$valid)
                return false;

            return vm.user.recuperar(vm.username).then(function(data)
            {
                if(data.code == 404)
                {
                    vm.ErrorRecuperar = true;
                    $timeout(function()
                    {
                        vm.ErrorRecuperar = false;
                    },5000);
                }

                if(data.code == 200){
                    vm.OkRecuperar = true;
                    $timeout(function()
                    {
                        vm.OkRecuperar = false;
                    },5000);

                }
                    
               
            });

        };
    }


})();