(function() {
    'use strict';
    /**
     * @memberof app
     *@ngdoc service
     *@name altmodLoginService
     *@description
     *Servicio de autores
     */

    angular
        .module('altmod.login')
        .factory('altmodLoginService', altmodLoginService);

    altmodLoginService.$inject = ["$http","$q","$window","$location"];

    function altmodLoginService($http,$q,$window,$location) {  
                console.log("altmod.login Servicio ENABLE");

        var user = {};

        /**
        * @ngdoc method
        * @name login
        * @memberof userService
        * @desciption
        * loguea a un usuario
        */
        user.login = function (username,password){
            var url= '/api/v1/login';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "LoginForm":{
                        "username":username,
                        "password":password
                    }
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };

      


        /**
        * @ngdoc method
        * @name recuperar
        * @memberof userService
        * @desciption
        * recuperar a usuario
        */
        user.recuperar = function (username){
            var url= '/api/v1/recovery-password';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "username":username,
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  

                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };


         /**
        * @ngdoc method
        * @name recuperar
        * @memberof userService
        * @desciption
        * recuperar a usuario
        */
        user.setPassword = function (token,password){
            var url= '/api/v1/set-password';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "token":token,
                    "password":password
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  

                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };

 
        /**
        * @ngdoc method
        * @name logout
        * @memberof userService
        * @desciption
        * hace logout.
        */
        user.logout = function()
        {
              var url= '/api/v1/logout';
             return $http({
                method  : 'POST',
                url     : url,
                data    : {
                    "LoginForm":{
                    }
                }
            })
                .then(Success)
                .catch(Failed);
            
            function Success(data){  
                return data.data;
            }

            function Failed(data){
                console.log(data);
                return false;
            }
        };



        


        return user;
    	
    }

})();