(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name altmodRegisterController
* @description
* This is main controller
**/ 

    angular
    .module('altmod.register')
    .controller('altmodRegisterController', altmodRegisterController);

    altmodRegisterController.$inject = ['altmodRegisterService',"$window","$timeout"];

    function altmodRegisterController(altmodRegisterService,$window,$timeout)
    {
    	console.log("register is enabled ");
    	var vm = this;
        vm.reg = altmodRegisterService;
        vm.user = {};
        vm.loginError = false;

        vm.registro = function(form)
        {
        	console.log("registro function");
            if(!form.$valid)
                return false;

            console.log("register user");

            return vm.reg.registro(vm.user).then(function(data)
            {
                console.log(data);
                if(data.code == 200){
                    
                    $window.location.reload();
                }else{
                    vm.loginError = true;
                    $timeout(function()
                    {
                        vm.loginError = false;
                    },5000);
                }

            });
        };

    }


})();